<template>
  <div class="page-header">
    <div>
      <h3>Edit Widget</h3>
      <h6 v-if="widget"><strong>{{ widget.details.name }}</strong></h6>
    </div>
    <router-link :to="{ name: 'Overview', params: { id: id } }" class="btn">Back</router-link>
  </div>
  <WidgetForm v-if="profile && widget" :widgetObj="widget" :isLoading="isLoading" @form-submit="handleSubmit" />
  <div v-else class="inner-container not-found">
    <h2>Uh-oh... Widget Not Found</h2>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { Toast } from '../composables/getGlobalVariables'
import getCollection from '../composables/getCollection'
import useFetch from '../composables/useFetch'
import WidgetForm from '../components/Widget/WidgetForm'

export default {
  components: { WidgetForm },
  props: ['id'],
  setup(props) {
    const router = useRouter()
    const { profile, widgets } = getCollection()
    const { patchData, error, isLoading } = useFetch()

    const widget = JSON.parse(JSON.stringify(widgets.value.find(el => el.id == props.id)))
    
    const handleSubmit = async (e, widget) => {
      await patchData(`/widgets/${props.id}`, {
        'details.name': widget.details.name,
        'details.domain': widget.details.domain,
        emojis: widget.emojis,
        settings: widget.settings,
        texts: widget.texts
      })
      
      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Awesome!',
          text: 'Widget updated successfully',
          footer: '<span style="text-align: center;">Note, if you\'re using the widget in an email, you\'ll have to re-embed it again, in order for the changes to be visible.</span>'
        })

        window.scrollTo({ top: 0 })
        router.push({ name: 'Overview', params: { id: props.id }})
      } else {        
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { profile, widget, handleSubmit, isLoading }
  }
}
</script>